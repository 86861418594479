import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "utils/appointment/customButton";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import CategoryForm from "./categoryForm";
import {
  mockSubCAtegoriesData,
  SubcategoriesColumns,
} from "./cotegoriesColumn";
import { convertToBase64 } from "hooks/useUtils";
import { useEcommerce } from "zustand/store/ecommerceStore";

const CategoryDetail = (props) => {
  const category = props.location.state;
  const [mode, setMode] = useState("");
  const [fileList, setFileList] = useState([]);

  const { loading, fetchSubCategoriesByCategoryId, addSubCategory } =
    useEcommerce((state) => ({
      loading: state.loading,
      fetchSubCategoriesByCategoryId: state.fetchSubCategoriesByCategoryId,
      addSubCategory: state.addSubCategory,
    }));

  useEffect(() => {
    fetchSubCategoriesByCategoryId(category?.id);
  }, [fetchSubCategoriesByCategoryId, category]);

  const handleAddForm = async (values, base64) => {
    if (fileList.length > 0) {
      const base64 = await convertToBase64(fileList);
      values["media"] = base64;
      addSubCategory(values);
    }
  };

  const renderDetail = () => {
    return {
      key: "Action",
      title: "Action",
      render: (category) => (
        <CustomButton textbutton="Detail" handleClick={() => {}} />
      ),
    };
  };

  return (
    <CustomCard
      loading={loading}
      title={
        mode !== "Add"
          ? "Liste des sous-categories"
          : "Ajouter une nouvelle sous-categorie"
      }
      extra={
        <div>
          {mode !== "Add" && (
            <CustomButton
              textbutton="Sous-category"
              handleClick={() => setMode("Add")}
              type="primary"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          )}
        </div>
      }
      children={
        <>
          {mode !== "Add" ? (
            <CustomTable
              dataList={mockSubCAtegoriesData}
              columns={[...SubcategoriesColumns, renderDetail()]}
            />
          ) : (
            <CategoryForm
              category="Sous-categorie"
              handleAddForm={handleAddForm}
              fileList={fileList}
              setFileList={setFileList}
            />
          )}
        </>
      }
    />
  );
};

export default CategoryDetail;
