import { create } from "zustand";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage, goBack } from "hooks/useUtils";
import {
  mockData,
  mockSubCAtegoriesData,
} from "views/app-views/ecommerce/categorie/cotegoriesColumn";
import { mockProductData } from "views/app-views/ecommerce/product/productColumns";
import { mockOrderData } from "views/app-views/ecommerce/orders/ordersColumns";

export const useEcommerce = create((set, get) => ({
  loading: false,
  categoriesData: mockData || [],
  subCategoriesData: mockSubCAtegoriesData || [],
  productsData: mockProductData || [],
  ordersData: mockOrderData || [],
  error: null,
  mode: "",

  setMode: (text) => {
    set((state) => {
      state.mode = text;
      return state;
    });
  },
  addCategory: (payload) => {
    set({ loading: true });
    fetch
      .post(``, payload)
      .then((res) => {
        set({ loading: false, subCategoriesData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },
  addSubCategory: (payload) => {
    set({ loading: true });
    fetch
      .post(``, payload)
      .then((res) => {
        set({ loading: false });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },
  addProduct: (payload) => {
    set({ loading: true });
    fetch
      .post(``, payload)
      .then((res) => {
        set({ loading: false, subCategoriesData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },
  fetchCategories: () => {
    set({ loading: true });
    fetch
      .get(``)
      .then((res) => {
        set({ loading: false, categoriesData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  fetchSubCategories: () => {
    set({ loading: true });
    fetch
      .get(``)
      .then((res) => {
        set({ loading: false, subCategoriesData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  fetchSubCategoriesByCategoryId: (id) => {
    set({ loading: true });
    fetch
      .get(``)
      .then((res) => {
        set({ loading: false, subCategoriesData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  fetchProducts: () => {
    set({ loading: true });
    fetch
      .get(``)
      .then((res) => {
        set({ loading: false, productsDataData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  fetchOrders: () => {
    set({ loading: true });
    fetch
      .get(``)
      .then((res) => {
        set({ loading: false, ordersData: res });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },
}));
