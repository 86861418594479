import { PAYMENT_METHODS } from "constants/ApiConstant";
import { create } from "zustand";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage } from "hooks/useUtils";
import { METHOD_ADDED, METHOD_UPDATED } from "constants/contentText";

export const usePaymentMethods = create((set) => ({
  loading: false,
  paymentMethods: [],
  error: null,
  isOpened: false,
  fileList: [],
  updateFileList: [],
  editingKey: "",

  setEditingKey: (value) => set({ editingKey: value }),

  setUpdateFileList: (file) => set({ updateFileList: file }),

  setFileList: (file) => set({ fileList: file }),

  handleOpen: () => set((state) => ({ isOpened: !state.isOpened })),

  fetchPaymentMethods: () => {
    set({ loading: true });
    fetch
      .get(PAYMENT_METHODS)
      .then((response) =>
        set({ paymentMethods: response.reverse(), loading: false })
      )
      .catch((error) => set({ error: error, loading: false }));
  },

  updatePaymentMethodById: (values, fileList, id) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("code", values.code);
    formData.append("type", values.type);
    formData.append("file", fileList[0].originFileObj);

    set({ loading: true });
    fetch
      .put(`${PAYMENT_METHODS}/${id}`, formData)
      .then(() => {
        set({ loading: false });
        alertMessage(METHOD_UPDATED, "success");
      })
      .catch((error) => {
        set({ error: error, loading: false });
        alertMessage(error?.message, "error");
      });
  },

  addPaymentMethod: (values, fileList, form) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("type", values.type);
    formData.append("code", values.code);
    formData.append("file", fileList[0]?.originFileObj);

    set({ loading: true });
    fetch
      .post(PAYMENT_METHODS, formData)
      .then(() => {
        set((state) => {
          state.fetchPaymentMethods();
          state.loading = false;
          form.resetFields();
          state.isOpened = false;
          state.fileList = [];
          return state;
        });
        alertMessage(METHOD_ADDED, "success");
      })
      .catch((error) => {
        set({ error: error, loading: false });
        alertMessage(error?.message, "error");
      });
  },
}));
