export const ordersColumns = [
  {
    key: "1",
    title: "id",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Patient",
    dataIndex: "patientName",
  },
  {
    key: "dae",
    title: "Phone Number",
    dataIndex: "phoneNumber",
  },
  {
    key: "namade",
    title: "Payment",
    dataIndex: "isPaid",
  },
  {
    key: "namadsae",
    title: "Delivered",
    dataIndex: "isDelivered",
  },
  {
    key: "nasfamade",
    title: "whatsapp",
    dataIndex: "isWhatsapp",
  },
];

export const mockOrderData = [
  {
    id: 1,
    patientName: "John Doe",
    phoneNumber: "+123456789",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 2,
    patientName: "Jane Smith",
    phoneNumber: "+987654321",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 3,
    patientName: "Alice Johnson",
    phoneNumber: "+456123789",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 4,
    patientName: "Bob Brown",
    phoneNumber: "+321654987",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 5,
    patientName: "Charlie Davis",
    phoneNumber: "+159753864",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 6,
    patientName: "Diana Green",
    phoneNumber: "+753951852",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 7,
    patientName: "Eva White",
    phoneNumber: "+852147963",
    isPaid: "PENDING",
    isDelivered: "ON PROCESS",
    isWhatsapp: "AVAILABLE",
  },
  {
    id: 8,
    patientName: "Frank Black",
    phoneNumber: "+369258147",
    isPaid: "COMPLETED",
    isDelivered: "DELIVERD",
    isWhatsapp: "NO",
  },
];
