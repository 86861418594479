import { Table } from "antd";
import React from "react";

const CustomTable = (props) => {
  const { dataList, columns } = props;

  return <Table dataSource={dataList} columns={columns} {...props} />;
};

export default CustomTable;
