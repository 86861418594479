import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Form, Input, Row, Space } from "antd";
import { SAVE } from "constants/contentText";
import moment from "moment";
import React from "react";
import CustomButton from "utils/appointment/customButton";
import CustomCard from "utils/customCard";
import { useHomeCare } from "zustand/store/homeCareStore";

const ServiceInvoicing = (props) => {
  const requestService = props?.location?.state;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    useHomeCare
      .getState()
      .setRequestPrice(requestService?.id, values, requestService?.requestType);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        items: [{}],
      }}
    >
      <CustomCard
        title={`Facturation du service ${requestService?.requestType}  `}
        extra={
          <Form.Item>
            <CustomButton textbutton={SAVE} htmlType="submit" />
          </Form.Item>
        }
        children={
          <>
            <Descriptions key={requestService.id} column={3}>
              <Descriptions.Item label={"Nom du patient"}>
                <strong>{requestService?.patient?.fullName}</strong>
              </Descriptions.Item>
              <Descriptions.Item label={"Numero du patient"}>
                <strong>{requestService?.patient?.phoneNumber}</strong>
              </Descriptions.Item>
              <Descriptions.Item label={"Age"}>
                <strong>{requestService?.patient?.age}</strong>
              </Descriptions.Item>

              <Descriptions.Item label={"Sexe"}>
                <strong>{requestService?.patient?.gender}</strong>
              </Descriptions.Item>

              <Descriptions.Item label={"Prestation"}>
                <strong>{requestService?.type}</strong>
              </Descriptions.Item>
              <Descriptions.Item label={"Heure de la prestation"}>
                <strong>{requestService?.requestedTime}</strong>
              </Descriptions.Item>
            </Descriptions>

            <div>
              <Form.List name="items" label="Categorie">
                {(fields, { add, remove }) => (
                  <>
                    {fields.length !== 0 &&
                      fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={10}>
                              {" "}
                              <Form.Item
                                {...restField}
                                name={[name, "label"]}
                                label={["description"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "label",
                                  },
                                ]}
                              >
                                <Input placeholder="Description" />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item
                                {...restField}
                                name={[name, "rate"]}
                                label={["price"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Prix obligatoire!!",
                                  },
                                ]}
                              >
                                <Input placeholder="Prix" type="number" />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              {" "}
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        </Space>
                      ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Ajouter champs de saissie
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </>
        }
      />
    </Form>
  );
};

export default ServiceInvoicing;
