import React, { useEffect, useState } from "react";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import { mockProductData, productsColumns } from "./productColumns";
import CustomButton from "utils/appointment/customButton";
import ProductForm from "./productForm";
import { convertToBase64 } from "hooks/useUtils";
import { useEcommerce } from "zustand/store/ecommerceStore";

const ProductList = () => {
  const [mode, setMode] = useState("");
  const [fileList, setFileList] = useState([]);

  const { loading, fetchProducts, productsData, addProduct } = useEcommerce(
    (state) => ({
      loading: state.loading,
      fetchProducts: state.fetchCategories,
      addProduct: state.addProduct,
      productsData: state.productsData,
    })
  );

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleAddForm = async (values) => {
    if (fileList.length > 0) {
      const base64 = await convertToBase64(fileList);
      values["media"] = base64;
      addProduct(values);
    }
  };

  return (
    <div>
      <CustomCard
        loading={loading}
        title="Liste des produits"
        extra={
          <div>
            {mode !== "Add" && (
              <CustomButton
                textbutton="Produit"
                handleClick={() => setMode("Add")}
                type="primary"
              />
            )}
          </div>
        }
        children={
          <>
            {mode !== "Add" ? (
              <CustomTable dataList={productsData} columns={productsColumns} />
            ) : (
              <ProductForm
                handleAddForm={handleAddForm}
                fileList={fileList}
                setFileList={setFileList}
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default ProductList;
