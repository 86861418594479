const dev = {
  // API_ENDPOINT_URL: 'http://190.92.134.163:5010'
  API_ENDPOINT_URL: 'https://api.yatamedical.info'
  //API_ENDPOINT_URL: "http://192.168.100.202:10000",
  //API_ENDPOINT_URL: "https://api.yatamedical.info",
  // API_ENDPOINT_URL: "http://192.168.100.66:10000",
  //API_ENDPOINT_URL: "http://192.168.100.197:10000",
  // API_ENDPOINT_URL: "https://yata-api.cloud.software-savants.com",
  //IMAGE_ENDPOINT_URL: "https://yata-api.cloud.software-savants.com",
  // IMAGE_ENDPOINT_URL: "http://192.168.100.66:4566",
  //API_ENDPOINT_URL: "http://192.168.100.27:10000",
  //  API_ENDPOINT_URL: "http://88.99.66.62:8200",

};

const prod = {
  //  API_ENDPOINT_URL: 'http://190.92.134.163:8090'
  API_ENDPOINT_URL: "https://api.yatamedical.info",
  // API_ENDPOINT_URL: "http://88.99.66.62:10000",
  //IMAGE_ENDPOINT_URL: "http://192.168.100.66:9000",
};

const test = {
  ///  API_ENDPOINT_URL: 'http://190.92.134.163:8090'
  API_ENDPOINT_URL: "https://api.yatamedical.info",
  // API_ENDPOINT_URL: "http://88.99.66.62:6060",
  // IMAGE_ENDPOINT_URL: "http://192.168.100.66:9000",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
