import { Form, Input } from "antd";
import React, { useState } from "react";
import CustomUploadFile from "utils/customUpload";
import CustomButton from "utils/appointment/customButton";
import { getBase64 } from "hooks/useUtils";

const CategoryForm = (props) => {
  const { category, handleAddForm, fileList, setFileList } = props;
  const [form] = Form.useForm();

  const handleForm = (values) => {
    handleAddForm(values);
  };

  const onFinishFailed = () => {
    console.log("");
  };
  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleForm}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
      form={form}
    >
      <Form.Item
        label={`Nom du ${category}`}
        name="categoryName"
        required={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Photo du categorie"
        name="fileList"
        required={[{ required: true }]}
      >
        <CustomUploadFile setFileList={setFileList} fileList={fileList} />
      </Form.Item>

      <Form.Item>
        <CustomButton
          htmlType="submit"
          type="primary"
          textbutton="Enregistre"
        />
      </Form.Item>
    </Form>
  );
};

export default CategoryForm;
